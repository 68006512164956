import React, { useState } from 'react';

import { jwtDecode } from "jwt-decode";
import  banner from '../../../images/Object.svg';

import './adminPasswordConfirmation.css';
import { bannerIcon, congratsIcon, logo } from '../../../images';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button, Form, Input } from 'antd';
import { InputComponent } from '../../../commonComponents';
import logo_icon from '../../../images/LaunchPad_300_80.svg';

const AdminPasswordConfirmation = () => {
    const [form] = Form.useForm();
    let navigate = useNavigate();
    let jwtConvert = jwtDecode 

    let dispatch = useDispatch();

    let onFinish = (values) =>{
            console.log(values,"adminFormValues")
            navigate("/tenantLogin")
    }

    let [loginDetails,setLoginDetails] = useState({
        password:"",
        confirm_password:""
    });

    let [adminReg,setAdminReg] = useState(false);
    let handleChange = (e,type) =>{
        setLoginDetails({...loginDetails,[type.name]:e.target.value})
    }

    
    return (
        <div className='adminLoginContainer'>
        <div className='adminLoginBanner'>
        <img src= {banner} className='bannerImage'/>

        </div>

        <div className='adminLoginForm'>
            <div className='adminLogo'>
            <img src={logo_icon}/>
            <img src={congratsIcon}/>
            </div>
        <Form 
        autoComplete="off"
        layout="vertical"
        form={form}
          onFinish={onFinish}
          >
        
        <><div className='fields'>
                          <Form.Item label="Password"
                              name="Password"
                              rules={[
                                  {
                                      required: true,
                                      message: 'password is required',
                                  },
                              ]}>
                              <InputComponent value={loginDetails.password} name={"password"} className='inputField'
                                  placeholder="Enter email"
                                  onChange={(e) => handleChange(e, { name: "password" })} />

                          </Form.Item>

                      </div><div className='fields'>
                              <Form.Item label="Confirm password"
                                  name="confirm_password"
                                  rules={[
                                      {
                                          required: true,
                                          message: 'Confirm password is required',
                                      },
                                  ]}>
                                  <Input.Password value={loginDetails.confirm_password} name={"confirm_password"} className='inputField adminInputField'
                                      placeholder="Enter password"
                                      onChange={(e) => handleChange(e, { name: "confirm_password" })} />

                              </Form.Item>

                          </div><div className='fields'>
                              <Form.Item>
                                  <Button htmlType='submit' primary type='primary' className='loginAdmin'>Login</Button>
                              </Form.Item>
                          </div></>
        
        </Form>
          
        </div>
        
    </div>
    );
}

export default AdminPasswordConfirmation;
