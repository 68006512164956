import React from 'react'
import { InputComponent } from '../../../commonComponents'
import { Button, Form, Input,notification } from "antd";

import { bannerIcon, logo,emailVerifyIcon } from '../../../images';
import logo_icon from '../../../images/LaunchPad_300_80.svg';

import './adminEmailVerify.css';
import ConfirmPassword from '../../Register/confirmPassword';
import { useNavigate } from 'react-router-dom';
const AdminEmailVerify=()=> {
  let navigate = useNavigate();
  const confirmPassword = () =>{
    navigate("/adminConfirmPassword")
  }
  return (
    
    <div className='adminLoginContainer'>
        <div className='adminLoginBanner'>
        <img src= {bannerIcon} className='bannerImage'/>
        </div>

        <div className='adminLoginForm'>
            <div className='adminLogo'>
            <img src={logo_icon}/>
            
            <img src={emailVerifyIcon} className='adminVerifyLogo'/>

            </div>

            <div className='text-center'>
              <h3>Verify Your Email Address</h3>
              <div>
                <p>Welcome to Akalaivan.com</p>
              </div>
              <div>
                <p>Please check your email address we sent confirmation mail</p>
                <p>Click below link to proceed your email</p>
              </div>

              <Button type='primary' primary onClick={confirmPassword}>PROCEED TO EMAIL</Button>
            </div>
        
        </div>
        
    </div>
  )
}

export default AdminEmailVerify